.App {
  display: flex;
  flex-direction: column;
}

.App .CodeMirror {
  border: 1px solid #eee;
  height: auto;
}

.App .Cell {
  margin: 10pt;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  display: flex;
  flex: 0;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(28pt);
  color: #494949;
  margin: 20pt;
}

.replErr {
  background-color: #ffdcdc;
  margin: 10pt;
}

.App-settings {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  margin-bottom: 20pt;
}

.Cell .removeCell {
  text-align: right;
}

.removeCell button {
  cursor: pointer;
}

.App-settings > * {
  margin-right: 10px;
}

.App-link {
  color: #09d3ac;
}

.App .examples {
  margin-left: 10pt;
}
